<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Loading",
  props: {
    show: {
      type: Boolean,
    },
  },
});
</script>

<template>
  <div id="overlay" class="spinner show" v-if="show">
    <div class="d-flex align-items-center justify-content-center">
      <img src="../assets/spinner.svg" />
    </div>
  </div>
</template>

<style>
#overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9990;
}
#overlay > div {
  height: 100%;
  user-select: none;
}
#overlay.spinner.show img {
  top: 50%;
  left: 50%;
  visibility: visible;
  transform: translateX(-50%) translateY(-50%);
  animation: rotation 1.5s cubic-bezier(0.85, 0.18, 0.24, 0.76) infinite !important;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
